import { getAuth, isAuthenticated } from '@services/identity.service';
import { isProtected } from './Security';
import useAuthChange from 'hooks/useAuthChange';
import { onAuthChange } from '@helpers/miscelleanous';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
    useAuthChange((token) => onAuthChange(token));
    const auth = getAuth();
    if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname)) {
        router.replace('/');
    }
    return children;
};

export default WithAuth;
