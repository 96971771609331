import { getAuth } from '@services/identity.service';
import { useState, useEffect } from 'react';

const useAuthChange = (onChange) => {
    const [authToken, setAuthToken] = useState(null);

    useEffect(() => {
        const checkAuthStatus = (token) => {
            setAuthToken(token || null);
            // Call the onChange callback with the updated authentication status
            if (typeof onChange === 'function') {
                onChange(token || null);
            }
        };
        checkAuthStatus(getAuth()?.token || null);

        document.addEventListener('cookie', checkAuthStatus);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('cookie', checkAuthStatus);
        };
    }, [onChange]);

    return authToken;
};

export default useAuthChange;
